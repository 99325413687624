<template>
    <div class='container'>
        <div class='field-title'>{{title}}</div>
        <select @change="selectChanged($event)">
            <option v-for="(item,index) in items" :key="index" :selected="index_value==index" >{{item}}</option>
        </select>
        
    </div>
</template>

<script>
export default {

    props: {
        title:{type: String, default: ''},
        items:{type:Array, default: ()=>{return []}},
        index_value:{type: Number, default: -1},
        
    },
    emits:['changed'],
    data(){
        return{
            // inputValue:'',
            // textfield_value:this.bind_value,
        } 
    },
    watch:{
        // bind_value(new_value){
        //     this.textfield_value = new_value;
        // }
    },
    methods:{
        selectChanged(event){
            let value = event.currentTarget.value;
            console.log(value);
            this.$emit('changed',value)
            
        },
        // enterPressed(par){
        //     this.$emit('enterPressed',par.currentTarget.value)
        // }
    }
}
</script>

<style scoped>
.container {flex:1 1 auto}
select {-webkit-appearance: none; -moz-appearance: none; appearance: none; margin-top:5px;
    width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
               box-sizing: border-box; border-radius: 5px; Height: 45px; }
</style>