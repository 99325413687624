<template>
    <section>
         <div>
            <slot></slot>
        </div> 
    </section>

</template>

<style scoped>
section{width:100%;  padding: 20px; overflow: hidden}
div {background: #FFFFFF; padding: 20px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); border-radius: 10px; width:100%; max-width:1200px }
</style>