<template>
    <div :class="style +' button-main-style' " @click="onClick" >
        <img class='icon' :src="iconSrc" >
    </div>

</template>

<script>
export default {
    props: {
        icon:{ type: String, default: ''},
        style:{ type: String, default: 'style1'},
        link:{ type: String, default: ''},
    },
    computed:{
        iconSrc(){
            let return_value = '';
            console.log('icon',this.icon);
            
            switch (this.icon.toLowerCase()) {
                case 'edit': return_value = require("/src/assets/icn_edit_grey.png");break;
                case 'delete': return_value = require("/src/assets/icn_delete_red.png");break;
                default: break;
            }
            return return_value;
        }
    },
    methods:{
        onClick(){
            this.$router.push(this.link);
        }
    }
}
</script>

<style scoped>
.button-main-style {display: flex; justify-content: center; align-items: center; cursor: pointer;}
.button-main-style:hover {background:var(--color-light-grey)}
.style1 {border-radius: 5px; width:30px; height: 30px;   }
.icon {width:16px; height:16px;}

</style>