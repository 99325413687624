<template>
    <section v-if="error_value.length>0">
        
         <div >
             <div class='panel-title'>Error</div>
             
             <div>{{error_value}} </div>
        </div> 
    </section>
</template>

<script>
export default {
    props:{
        error_value:{ type: String, default: ''},
    },
    // computed: {
    //         hasSlotValue() {
    //             console.log('ee',this.$el.getElementsByClassName("slot-wrapper")[0].innerHTML);

    //             // console.log(this.$slots);
                
    //             // console.log('hasSlotValue',!!this.$slots.default);
                
    //             return !!this.$slots.default
    //         }
    //     }
}
</script>
<style scoped>
section{width:100%;  padding: 20px; overflow: hidden}
section > div {color:red; background: #ffcccc; padding: 20px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); border-radius: 10px; width:100%; max-width:1200px }
.panel-title {color:black; margin-bottom: 20px; font-size:20px; font-weight: 700}
</style>