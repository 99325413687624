<template>
<section style=''>
  
  <page-header> {{$route.params.user_id ? "Edit User" : "New User"}}</page-header>
  <panel>
      <div> <h2>Portal User Information</h2></div>

      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-11'>
            <editbox class='' title="Full name" :bind_value="user_name" @changed="val => user_name = val"></editbox>
        </div>

        <div class='flex-11'>
            <editbox class='' title="User's email" :bind_value="user_email" @changed="val => user_email = val"></editbox>
        </div>

    
      </div> 
        
        

      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-11'>
            <editbox class='' title='Password' :bind_value="password" @changed="val => password = val"></editbox>
        </div>

        <div class='flex-11'>
            <editbox class='' title='Repeat Password' :bind_value="password_repeat" @changed="val => password_repeat = val"></editbox>
        </div>
      </div>

      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-11'>
            <list-radios  title='Access' :options="access_options" @stringOptions="val => access = val"></list-radios>
        </div>
        <div class='flex-11'></div>
      </div>

      


  </panel>

  <error-panel :error_value="error_value" ></error-panel>

  <container>
    <div class='flex flex-row-reverse'>
      
        <button-s1 class='width-200' :onClick="saveUserPressed">{{$route.params.user_id ? "Edit User" : "Create User"}}</button-s1>
     
    </div>
    
  </container>


</section>
</template>

<script>
import functions from '@/functions.js'
export default {
  components: {
  },
  data(){
    return{
      user_name:'',
      user_email:'',
      password:'', 
      password_repeat:'', 
      error_value:'',
      access:'111'
      
    }
  },
  computed:{
    access_options(){
      let array_access = [...this.access];
      console.log('array_access',array_access);
      
      let return_value =  [
        {title:'Add / Edit Organisation', selected:(array_access[0] == 1)},
        {title:'Add / Edit Users', selected:(array_access[1] == 1)},
        {title:'View Logs', selected:(array_access[2] == 1)},
      ];
      console.log('return_value',return_value);
      return return_value;
      
      


    }
  },
  methods:{
    
    async saveUserPressed(){
      this.error_value = '';
      if (!functions.validateEmail(this.user_email)) this.error_value = 'Email is invalid.';
      else if (this.password != this.password_repeat) this.error_value = "Emails don't match.";
      else if (this.user_name.length < 4) this.error_value = 'Please enter full name.';
      else 
      {
        let params = {
                name: this.user_name,
                email: this.user_email,
                password: this.password,
                access: this.access
          }
          if (this.$route.params.user_id){
            params['table_id'] = this.$route.params.user_id
          }
          console.log(params);

          let result = await this.$store.dispatch('requests/sendRequest', {
            endpoint:'users',
            params:params,
            method:'post'           
          });

          if (result.result == 'success'){
            this.$router.push('/users/list');
          }
          else this.error_value = 'Error registering user.';
            
      }
      
      

      // user_id: this.$route.params.codeid ?? 0,


      
      // this.error_value = '';
      // if ((this.scheme_id == 0) && (this.venue_id == 0)) this.error_value = 'You need to set Loyalty Scheme ID or Venue ID.';
      // else 
      // {
      //     
        
      //     console.log(params);
          
    

      //   // let result_data = result.list ?? [];
      //   // this.received_codes = result_data;
     
      // }
      
    },
    async getPageData(){

        let user_id = this.$route.params.user_id ?? 0;

        if (user_id > 0)
        {
          let params = {
            table_id : user_id
          }
          
          let result = await this.$store.dispatch('requests/sendRequest', {
            endpoint:'users',  
            method:'get',       
            params:params             
          });

          if (result.length > 0)
          {
            let user_data = result[0];
            this.user_name = user_data.name ?? '';
            this.user_email = user_data.email ?? '';
            // this.password = user_data.password ?? '';
            // this.password_repeat = user_data.name ?? '';
            this.access = user_data.access ?? '';
         
            
          }
          

          // this.analyseTableData(result ?? [])
        }
        // else 
        // {

        // }
        
        
    },
    
  },
  watch: {
    $route() {
      this.getPageData();
    }
  },
  mounted(){
    this.getPageData();
  },
}
</script>

<style scoped>
.flex-12{flex: 2 1;}
</style>