import axios from 'axios';

export default {

  async sendRequest({rootGetters}, params) {
  
      // let request_result = false;
      let url = process.env.VUE_APP_API_URL+params.endpoint;   
      let api_params = params.params
      let method = params.method ?? 'get';
      const data = new FormData() 
      
      
      if (url && api_params)
      {
        data.append('params', JSON.stringify(api_params))
      }
      else 
      {
        console.log("params doesn't have required fields (url,api_params)");
      }
      
      let token = rootGetters.getToken;
      data.append('token', token);
      data.append('method', method);
      
      
      let received_data = {};
      await axios({ 
        method: 'post',
        url: url,
        headers: { 'content-type': 'application/json', 'Accept': 'application/json' },
        data: data
      })
      .then((response) => {
        console.log('response',response);
        
        let api_result = response.data.result;
        if (api_result == 'success')
        {
          if (response.data['array_values']) received_data = response.data['array_values'];
          else received_data = response.data;
        }
        
        
      });

      return received_data;
  } 
};
