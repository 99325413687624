<template>
    <div class='container'>
        <div class='field-title'>{{title}}</div>
        
        <div class='radio-container'> 
            <div v-for="(item,index) in radio_options" :key="index">
                <div class='flex mt-10' @click="lineClicked(index)">
                    <div class='circle-container' >
                        <div :class="'circle-background '+(item.selected ? 'selected':'')"> 
                            <div v-if="item.selected"><i class="fas fa-check" ></i></div>
                        </div>
                    </div>
                    <div class='field-title' >{{item.title}}</div>

                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
// options:[{title:'sample1', selected:true},
//          {title:'sample2', selected:false},]
    props: {
        
        title:{ type: String, default: ''},
        options:{type:Array, default: ()=>{return []}},
        // type:{ type: String, default: 'text'},
        // readonly:{type: Boolean, default:false},
        
        // default:{ type: String, default: ''},
        // identifier:{ type: String, default: ''},
        // bind_value:{ type: String, default: ''},
        // min:{ type: Number, default: -1},
        // max:{ type: Number, default: -1},

        // default_date:{ type: Date, default: function () { return new Date() } }, 
    },
    emits:['stringOptions'],
    computed:{
        radio_options(){
            return this.options;
        }
    },
    data(){
        return{
            
                // {title:'sample1', selected:true},
                // {title:'sample2', selected:false},
                // {title:'sample3', selected:true},
            
            // date: this.default_date,
            // inputValue:'',
            // textfield_value:this.bind_value,
        } 
    },
    // watch:{
    //     bind_value(new_value){
    //         this.textfield_value = new_value;
    //     },
    //     date(new_value){
    //         this.$emit('changed',new_value)
    //         console.log(new_value);
    //     },
    // },
    methods:{
        lineClicked(index){
            this.radio_options[index].selected = !this.radio_options[index].selected;
            
            let stringOptions = '';
            this.radio_options.forEach(element => {
                stringOptions += (element.selected == true) ? '1':'0';
            });
            this.$emit('stringOptions',stringOptions);
            
        }
        // inputChanged(par){
        //     console.log('----',this.value);
            
        //     let this_value = par.currentTarget.value;
        //     if (this.min != -1){
        //         if (parseInt(this_value) < this.min) {this.textfield_value = this.min; this_value = this.min;}
        //     }
        
        //     if (this.max != -1){
        //         if (parseInt(this_value) > this.max) {this.textfield_value = this.max; this_value = this.max;}
        //     }
            
        
        
        
        //     if (this.identifier.length > 0)
        //         this.$emit('changed',{value: this_value, identifier: this.identifier})
        //     else
        //         this.$emit('changed',this_value)

            
        // },
        // enterPressed(par){
            
            
        //     if (this.identifier.length > 0)
        //         this.$emit('enterPressed',{value: par.currentTarget.value, identifier: this.identifier})
        //     else
        //         this.$emit('enterPressed',par.currentTarget.value)
        // }
    }
}
</script>

<style scoped>
.container {flex:1 1 auto}
.radio-container {width:100%; margin-top:5px;
    background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
    box-sizing: border-box; border-radius: 5px; Height: 150px;}
.circle-container {margin-right: 10px;}

.circle-background {width:16px; height:16px; border-radius: 50%; border: 2px solid  var(--color-main-color);
                    display: flex; justify-content: center; align-items: center; margin-top:2px}

.circle-background.selected{background: var(--color-main-color);}
.circle-background.selected > div {color:white; font-size: 12px;}
</style>