<template>
<section>
    <div class='container'>
        <div class='field-title'>{{title}}</div>
        <div class='panel-container'>
          <table class='width-100p'>
            <thead >
                <tr >
                    <td v-for="item in header_titles" :key="item"> <div class='mt-10'>{{item}}</div> 
                      <hr style='width:100%; margin:5px 0'>
                    </td>
                    
                </tr>
                
            </thead>
            
            <tbody>
               
                <tr v-for="(line_value,index_line) in items_value" :key="index_line">
                    <td v-for="(item_type,index_type)  in items_type" :key="index_type">
                        
                        <div v-if="item_type.type == 'label' "> {{line_value[index_type]}}  </div>
                        
                        <div v-if="item_type.type == 'number-edit-box' "> 
                            <editbox type='number' class='' :identifier="index_line+'_'+index_type" :bind_value=line_value[index_type] @changed="editboxChanged"></editbox> 
                        </div>
                        
                    </td> 
                </tr>
                
            </tbody>
          </table> 
        </div>
    </div>

    
</section>
</template>

<script>
export default {
    props: {
        title:{ type: String, default: ''},
        header_titles:{type:Array, default: ()=>{return []}},
        items_type:{type:Array, default: ()=>{return []}},
        items_value:{type:Array, default: ()=>{return []}},
        lines:{type:Array, default: ()=>{return []}}
    },
    emit:['itemsValueChanged'],
    data(){
        return{
            list_value:this.items_value
           // header_titles:['asas','sdsd']
        }
    },
    methods:{
        editboxChanged(received_value){
            let value = received_value.value ?? '';
            let identifier = received_value.identifier ?? '';

            let array_identifier = identifier.split('_');
            let i = array_identifier[0];
            let j = array_identifier[1];
            this.list_value[i][j] = value;
            this.$emit('itemsValueChanged',{value:this.list_value})
        },
        converted_item_value(value){

            if (typeof value === 'string' || value instanceof String)
                return value;
            else
                return '@@' 

        },
        csvClicked(){
            let csv = this.header_titles.join(',')+'\n';

            this.lines.forEach((row) => {
                csv += row.join(',');
                csv += "\n";
            });
      
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'embargo.csv';
          anchor.click();
            
        }
    }
}
</script>

<style scoped>
section{width:100%;  overflow: hidden}
.panel-container{margin-top:5px; width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
               box-sizing: border-box; border-radius: 5px; Height:250px; overflow: scroll}
table {font-size: 12px;}
thead td{margin: 10px 0}
thead td {position: sticky; top: 0; background: white}
/* 
.main-container {background: #FFFFFF; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); border-radius: 10px; width:100%; overflow: hidden; max-width:1200px }

td {padding-left: 5px}
table{width:100%;  border-spacing: 0px;border-collapse: collapse;}
thead tr {height: 50px; background:#DCDBDD; font-size: 14px;}
tbody tr {height: 40px; border-bottom: 1px solid #edeff2}
tbody td {font-size: 12px; }
tbody tr:hover {background-color:#f5f5f5;}
.buttons-container{display: flex; margin-top: 10px; flex-direction: row-reverse} */

</style>