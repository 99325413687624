import { render } from "./App.vue?vue&type=template&id=07823381&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "./App.vue?vue&type=style&index=0&id=07823381&scoped=true&lang=css"
import "./App.vue?vue&type=style&index=1&id=07823381&lang=scss"
import "./App.vue?vue&type=style&index=2&id=07823381&lang=css"
script.render = render
script.__scopeId = "data-v-07823381"

export default script