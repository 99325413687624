<template>
<section>
    <div class='main-container'>
        <table>
            <thead>
                <tr>

                    <td v-for="(item ,index) in header_titles" :key="index">

                        <div v-if="typeof item === 'object' && item.type=='checkbox'">
                             <input :checked="item.value" v-on:input="checkboxEvent" type="checkbox" :id="'checkbox_header_'+index" >
                        </div>
                        <div v-else> {{item}}</div>

                    </td>
                </tr>
                
            </thead> 
            
            <tbody>
                <tr v-for="(line_item,line_index) in lines" :key="line_index">
                    <td v-for="(item,index_column) in line_item" :key="index_column">
                        
                        <div v-if="typeof item === 'object' && item.type=='checkbox'">
                             <input :checked="item.value" v-on:input="checkboxEvent" type="checkbox" :id="'checkbox_'+line_index+'_'+index_column" >
                        </div>
                        <div v-else-if="typeof item === 'object'"> 
                             <div v-for="(item_column,index_index_column) in item" :key="index_index_column">
                                 <button-icon :icon="item_column.icon" :link="item_column.link"> </button-icon>
                             </div>
                        </div> 

                        <div v-else> {{converted_item_value(item)}}</div>

                    </td>
                </tr>
                <tr v-if="lines.length == 0">
                    <td> No item to show</td>
                </tr>
            </tbody>
        </table> 
    </div>
    
    <div class='buttons-container'>
        <div>
        <button-s1 class='width-150' :onClick="csvClicked" v-if="lines.length > 0">Download CSV</button-s1>
        </div>
    </div>
    
  
    
</section>
</template>

<script>
/*
lines: string 
       dict (type,value)
       array of dictionary (for icnon button: {type:'icon-button', link:'/vene', icon:'edit'})
*/
export default {
    props: {
        title:{ type: String, default: ''},
        header_titles:{type:Array, default: ()=>{return []}},
        lines:{type:Array, default: ()=>{return []}}
    },
    data(){
        return{
           // header_titles:['asas','sdsd']
        }
    },
    emits:['valueChanged'],
    methods:{
        checkboxEvent(event){
            this.$emit('valueChanged',{id: event.target.id, value:event.target.checked})
        },
        converted_item_value(value){
            
            if (typeof value === 'string' || value instanceof String)
                return value;
            else
                return String(value)

        },
        csvClicked(){
            let array_header_items = [];
            this.header_titles.forEach(h_item => {
                if (typeof h_item === 'object')
                    array_header_items.push(h_item['value'] ?? '');
                else 
                    array_header_items.push(h_item);
            });
            let csv = array_header_items.join(',');
            csv += "\n";
           

            this.lines.forEach((row) => {
                let array_line_items = [];
                row.forEach(row_item => {
                    if (typeof row_item === 'object')
                        array_line_items.push(row_item['value'] ?? '');
                    else 
                        array_line_items.push(row_item);
                    
                });
                csv += array_line_items.join(',');
                csv += "\n";
            });
      
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'embargo.csv';
          anchor.click();
            
        }
    }
}
</script>

<style scoped>
section{width:100%;  padding: 5px 20px; overflow: hidden}
.main-container {background:white; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); border-radius: 10px; width:100%; overflow: hidden; max-width:1200px }

td {padding-left: 5px}
table{width:100%;  border-spacing: 0px;border-collapse: collapse;}
thead tr {height: 50px; background:var(--color-light-grey); font-size: 14px;}
tbody tr {height: 40px; border-bottom: 1px solid #edeff2}
tbody td {font-size: 12px; }
tbody tr:hover {background-color:#f5f5f5;}
.buttons-container {width:100%; overflow: hidden; max-width:1200px; margin-top:10px}
.buttons-container > div {display: flex; margin-top: 10px; flex-direction: row-reverse}
/*
thead td {font-weight: 700; font-size: 14px;}

tr{height: 30px;} */
</style>