
<template>
    <section class='header-main-container'>
        <div class='header-container'>
            <div class='header-items'>
                <a href='/home' style='font-size:28px'>Innstrat</a>
            </div>
            <div class='header-items'>
                <img @click="logoutPressed" class='logout-img' :src="require('@/assets/icn_logout.svg')">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    methods:{
        logoutPressed(){
            this.$store.dispatch('signout');
            this.$router.replace('/login');
        }
    }
}
</script>

<style scoped lang="scss">
img{width:220px}
.header-main-container{position: fixed; padding: 0 10px; width:100%; height:75px; background: white; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); z-index:100;
    .header-container{ display: flex; width: 100%; justify-content: space-between;height:75px; align-items: center;
        .logout-img {width:25px; height:25px; }

    }
}

</style>