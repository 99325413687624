<template>
<section style=''>
  
  <page-header> {{$route.params.org_id ? "Edit Organisation" : "New Organisation"}}</page-header>
  <panel>
      <div> <h2>Organisation Information</h2></div>

      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-11'>
            <editbox class='' title='Organisation Name *' :bind_value="state.organisation_name" @changed="val => state.organisation_name = val"></editbox>
            <p class='field-explain'> Organisation name that will apear in their portal.</p>
        </div>

        <div class='flex-11'>
            <editbox class='' title='Contact Number' :bind_value="state.contact_number" @changed="val => state.contact_number = val"></editbox>
            <p class='field-explain'> Enter contact number with country code.</p>
        </div>

        <div class='flex-11'>
            <editbox class='' title='Email *' :bind_value="state.email" @changed="val => state.email = val"></editbox>
            <p class='field-explain'> Enter oganisation email.</p>
        </div> 
      </div> 
        
        

      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-12'>
            <editbox class='' title='Address' :bind_value="state.address" @changed="val => state.address = val"></editbox>
            <p class='field-explain'> Enter organisation address.</p>
        </div>

        <div class='flex-11'>
            <editbox class='' title='Country' :bind_value="state.country" @changed="val => state.country = val"></editbox>
            <p class='field-explain'> Enter full country name.</p>
        </div>
      </div>


      <div class='flex flex-space-between gap-20 mt-40'>
        <div class='flex-12'>
            <editbox class='' title='Website' :bind_value="state.website" @changed="val => state.website = val"></editbox>
            <p class='field-explain'> Enter organisation website.</p>
        </div>

        <div class='flex-11'>
            
        </div>
      </div>
  </panel>

  <panel v-if="!$route.params.org_id">
    <div> <h2>Admin Credentials </h2></div>
    <div class='flex flex-space-between gap-20 mt-40'>
            <div class='flex-11'>
                <editbox class='' title='Admin Email *' :bind_value="state.admin_email" @changed="val => state.admin_email= val"></editbox>
                <p class='field-explain'> Will be used for adminisrator login .</p>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Admin password *' :bind_value="state.admin_password" @changed="val => state.admin_password= val"></editbox>
            </div>

            <div class='flex-11'>
            </div> 
       
    </div>

  </panel>


  <panel>
    <div> <h2>Contact Info</h2></div>
    <div class='flex flex-space-between gap-20 mt-40'>
            <div class='flex-11'>
                <editbox class='' title='Person Name' :bind_value="state.person_name" @changed="val => state.person_name = val"></editbox>
                <p class='field-explain'> Organisation name that will apear in their portal.</p>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Person Contact Number' :bind_value="state.person_contact_number" @changed="val => state.person_contact_number = val"></editbox>
                <p class='field-explain'> Enter contact number with country code.</p>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Person Email' :bind_value="state.person_email" @changed="val => state.person_email = val"></editbox>
                <p class='field-explain'> Enter oganisation email.</p>
            </div> 
          </div>


          <div class='flex flex-space-between gap-20 mt-40'>
            <div class='flex-11'>
                <editbox class='' title='Reseller ID' :bind_value="state.reseller_id" @changed="val => state.reseller_id = val"></editbox>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Promo Code' :bind_value="state.promo_code" @changed="val => state.promo_code = val"></editbox>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Referral Code' :bind_value="state.referral_code" @changed="val => state.referral_code = val"></editbox>
            </div> 
          </div>
  </panel>

  <panel>
    <div> <h2>Subscription</h2></div>
    <div class='flex flex-space-between gap-20 mt-40'>
            <div class='flex-11'>
                <select-box title='Subscription Status' :index_value="state.status" :items="['Trial','Active','Past Due','Suspended', 'Cancelled']" @changed="statusChanged"></select-box>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Trial End Date' :default_date="state.trial_date" @changed="val => state.trial_date = val" type='date-picker'></editbox>
            </div>

            <div class='flex-11'></div>

    </div>

    <div class='flex flex-space-between gap-20 mt-40'>
           <div class='flex-11'>
                <editbox class='' title='Activation Date' :default_date="state.activation_date"  @changed="val => state.activation_date = val" type='date-picker'></editbox>
            </div>

            <div class='flex-11'>
                <editbox class='' title='License Expiry Date' :default_date="state.license_expire_datetime"  @changed="val => state.license_expire_datetime = val" type='date-picker'></editbox>
            </div>

            <div class='flex-11'></div>
    </div>

    <div class='flex flex-space-between gap-20 mt-40'>
            <div class='flex-11'>
                <editbox class='' title='Stripe Customer id' @changed="resellerIDChanged" readonly='true'></editbox>
            </div>

            <div class='flex-11'>
                <editbox class='' title='Subscription Fee (per user)' :bind_value="state.subscription_user_fee" @changed="val => state.subscription_user_fee = val"></editbox>
            </div>

            <div class='flex-11'>
              <editbox class='' title='Number of Users' :bind_value="state.user_number" @changed="val => state.user_number = val"></editbox>
              
            </div>
    </div>
  </panel>

  <error-panel :error_value="state.error_value" ></error-panel>

  <container>
    <div class='flex flex-row-reverse'>
      <button-s1 class='width-200' :onClick="saveOrganisation">{{$route.params.org_id ? "Edit Organisation" : "Create Organisation"}}</button-s1>
    </div>
  </container>


</section>
</template>

<script>
import { onMounted,reactive,watch} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import functions from '@/functions.js'

export default {
  components: {
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore();
    const state = reactive({
      organisation_name: '',
      contact_number:'',
      email:'',
      address:'', 
      country:'UK', 
      website:'', 
      person_name:'', 
      person_contact_number:'', 
      person_email:'', 
      reseller_id:'', 
      promo_code:'', 
      referral_code:'', 
      error_value:'',
      status:0,
      activation_date: '',
      trial_date: new Date() , // functions.dateToStr(new Date),
      license_expire_datetime: new Date() ,
      subscription_user_fee:0,
      user_number:10,
      admin_email:'',
      admin_password:'',

    })

    
    
    const saveOrganisation = async () => {
      let org_id = route.params.org_id ?? 0;

      if (state.organisation_name.length < 3) state.error_value = "Organisation name is required.";
      else if (state.email.length < 3) state.error_value = "Organisation email is required.";
      else if (state.person_name.length < 3) state.error_value = "Person Name email is required.";
      
      else if (!functions.validateEmail(state.email)) state.error_value = "Organisation email is invalid.";
      else if ((org_id==0) && (state.admin_email.length < 3)) state.error_value = "Admin email is required.";
      else if ((org_id==0) && (!functions.validateEmail(state.admin_email))) state.error_value = "Admin email is invalid.";
      else if ((org_id==0) && (state.admin_password.lengst < 3)) state.admin_password = "Admin password is required.";  
      else 
      {
          let sending_activation_date = '2000-01-01 01:01:01';
          if (state.activation_date  instanceof Date){
              sending_activation_date = functions.dateToStr(state.activation_date);
          }

          let reseller_id = parseInt(state.reseller_id)
          if  (isNaN(reseller_id)) reseller_id = 0;

          let params = {
              organisation_name : state.organisation_name ,
              contact_number : state.contact_number ,
              email : state.email ,
              address : state. address,
              website : state.website ,
              country : state.country ,
              person_name : state.person_name ,
              person_contact_number : state.person_contact_number ,
              person_email : state.person_email ,
              promo_code : state.promo_code ,
              referral_code : state.referral_code ,
              reseller_id : reseller_id ,
              subscription_status : state.status ,
              activation_datetime : sending_activation_date,
              trial_end_date : functions.dateToStr(state.trial_date),
              license_expire_datetime : functions.dateToStr(state.license_expire_datetime),
              subscription_user_fee:state.subscription_user_fee,
              user_number:state.user_number,
              admin_email:state.admin_email,
              admin_password:state.admin_password
          }

          if (org_id > 0) params['organisation_id'] = org_id;

          let result = await store.dispatch('requests/sendRequest', {
              endpoint:'organisations',
              method:'post',
              params:params             
          });
          console.log(result);
          
          if (result.error_message)
          {
            state.error_value = result.error_message;
          }
          else if ((result.result) && (result.result == 'success'))
          {
            router.push('/organisations/list');
          }
          // else if (result.error_message)
          // {
          //   state.error_value = result.error_message;
          // }

          // if (result.result)
      }


      // let result_data = result.list ?? [];
      // this.received_codes = result_data;

    }

    const statusChanged = (received_value) => { 
        switch (received_value) {
          case 'Trial': state.status=0; break;
          case 'Active': state.status=1; break;
          case 'Past Due': state.status=2; break;
          case 'Suspended': state.status=3; break;
          case 'Cancelled': state.status=4; break;
          default: state.status=0; break;


        }
    }


    watch(route, () => {
      getPageData();
      
    });

    const getPageData = async () => {
    
        let org_id = route.params.org_id ?? 0;
        
        
        if (org_id > 0)
        {
          let params = {
            organisation_id : org_id
          }
          
          let result = await store.dispatch('requests/sendRequest', {
            endpoint:'organisations', 
            method:'get',       
            params:params             
          });

          if (result.length > 0)
          {
              let org_data = result[0];

              state.organisation_name = org_data['organisation_name'];
              state.contact_number = org_data['contact_number'];
              state.email = org_data['email'];
              state.address = org_data['address']; 
              state.country = org_data['country']; 
              state.website = org_data['website'];
              state.person_name = org_data['person_name'];
              state.person_contact_number = org_data['person_contact_number']; 
              state.person_email = org_data['person_email'];
              state.reseller_id = org_data['reseller_id'];
              state.promo_code = org_data['promo_code']; 
              state.referral_code = org_data['referral_code']; 
              state.user_number = org_data['user_number'];
              state.status = org_data['subscription_status'];
              state.subscription_user_fee = org_data['subscription_user_fee'];

              state.trial_date = new Date(org_data['trial_end_date'].split(' ')[0]);


              let license_expire_date = org_data['license_expire_datetime'].split(' ')[0];
              if (license_expire_date != '2010-01-01')
                license_expire_date = new Date(license_expire_date);
              else license_expire_date = '';
              state.license_expire_datetime = license_expire_date;


              let activation_date = org_data['activation_datetime'].split(' ')[0];
              if (activation_date != '2000-01-01')
                activation_date = new Date(activation_date);
              else activation_date = '';
              state.activation_date = activation_date;
          }
        }
        else 
        {
            var trial_date = new Date(); // Now
            trial_date.setDate(trial_date.getDate() + 30)
            state.trial_date = trial_date;
        }
        
    }


    onMounted(getPageData) 
    return {
      saveOrganisation, statusChanged,
      state
    }


  },

  
}
</script>

<style scoped>
.flex-12{flex: 2 1;}
</style>


