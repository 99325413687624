<template>
<section style=''>
  
  <page-header>ORGANISATIONS</page-header>

  <table-s1 class='mt-20' title="Order list" :header_titles=table_header_titles :lines=table_lines></table-s1>

</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      table_header_titles : ['ID',	'Organisation',	'Contact Number',	'Email'	,'Subscription Status',	'Registered',''],
      table_lines:[]
    }
  },
   
  methods:{
    async getPageData(){
      
        let params = {
        }
        
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'organisations',  
          method:'get',       
          params:params             
        });

        // let array_values = result ?? [];
        // if (array_values['array_values']) array_values = array_values['array_values'];
        // else array_values['array_values'] = [];

        this.analyseTableData(result ?? [])
        
    },
    analyseTableData(result_data){
      
      this.table_lines = [];
      // let active = {'0':'Inactive', '1':'Active', '2':'Claimed'};
      

      result_data.forEach(line => {
        console.log(line);
        let arrayStatus = ['Trial','Active','Past Due','Suspended', 'Cancelled']
        let subscription_status = ''
        if (line.subscription_status>=0 && line.subscription_status<5)
          subscription_status = arrayStatus[line.subscription_status]

        
        this.table_lines.push([
          line.organisation_id,
          line.organisation_name, 
          line.contact_number, 
          line.email, 
          subscription_status,
          line.register_datetime,
          [{type:'icon-button', link:'/organisation/edit/'+line.organisation_id, icon:'edit'}]
          
        ]);
      });

      // [{type:'icon-button', link:'/stampcodes/edit/'+line.stamp_code_id, icon:'edit'}]
      // ['ID',	'Organisation',	'Contact Number',	'Email'	,'Subscription Status',	'Registered'],
      // activation_datetime: "2020-12-28 00:00:00"
      // address: ""
      // communication_preferences: ""
      // contact_number: "77"
      // country: "UK"
      // email: "reza@sample.com"
      // extra_users: "0"
      // info_json: ""
      // organisation_id: "1"
      // organisation_name: "Sample"
      // payment_customer_id: ""
      // payment_customer_token: ""
      // payment_system_id: "0"
      // person_contact_number: ""
      // person_email: ""
      // person_name: ""
      // promo_code: ""
      // referral_code: ""
      // register_datetime: "0000-00-00 00:00:00"
      // reseller_id: "0"
      // server_id: "0"
      // server_json: ""
      // subscription_fee: "0"
      // subscription_frequency: "0"
      // subscription_id: "0"
      // subscription_status: "0"
      // subscription_user_fee: "0"
      // trial_end_date: "2021-01-28 00:00:00"
      // website: "www.sample.com"

    }
    
  },
  mounted(){
    this.getPageData();
  }
}
</script>

<style scoped>

</style>