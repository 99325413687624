export default {
  isLoggedin(state) {
    return !!state.token;
  },
  getToken(state){
    console.log('state',state);
    console.log('statetoken',state.token);
  
    return state.token;
  }


};