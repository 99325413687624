
<template>
<section >
    
   <ul class='main-nav mt-30'>
    <li v-for="(nav_item, index) in nav_items" :key="nav_item.title" 
        @mouseover="nav_item.hover = true"
         >
         <div class='flex items-center'>
             <!-- <div class='icon-container'> -->
             <img :src="require('@/assets/'+nav_item.icon+'.svg')">
             <!-- </div> -->
  
             <a @click="navItemClicked(index)" class='nav-title ml-10'>{{ nav_item.text }}</a>
         </div> 
        
         <transition name = 'nav-trans'
                    @enter="navTransEnter"
                    @after-enter="navTransAfterEnter"
                    @before-leave="navTransBeforeLeave"
                    @after-leave="navTransAfterLeave">
            <ul v-if="nav_item.is_open" class='pt-10'>
                <li v-for="(nav_sub_item,sub_index) in nav_item.items" :key="nav_sub_item.text">
                   <a @click="navSubItemClicked(index,sub_index)" :key="nav_sub_item.text" class='nav-sub-title'> {{ nav_sub_item.text }} </a>
                </li>
            </ul>
         </transition>
    </li>
   </ul>

</section>
</template>

<script>

export default {
    data(){
        return{
            isShow:false,
            nav_items:[
                {text:'Organisations', icon:'icn_report_white', is_open:false, items:[
                    {text:'New Organisation',link:'/organisation/new'},
                    {text:'Organisation List',link:'/organisations/list'}]},

                {text:'CRM Users', icon:'icn_user', is_open:false, items:[
                    {text:'User List', link:'/users/list'},
                    {text:'New User',link:'/user/new'}], },
                {text:'Logs', icon:'icn_logs', is_open:false, items:[
                    {text:'Api Logs', link:'/logs/api'},
                    {text:'System Logs', link:'/logs/system'},
                    {text:'Error Logs',link:'/logs/error'},
                    {text:'Dev Logs',link:'/logs/dev'}], },
                {text:'Tasks', icon:'icn_task', is_open:false, items:[
                    {text:'Log out', link:'/logs/api'}]},
                // {text:'Tasks', icon:'icn_task', is_open:false, items:[
                //     {text:'New Notificatoin', link:'/notification/new'},
                //     {text:'Notifications', link:'/notification/list'},
                //     {text:'Generate Promocode', link:'/promocodes/generate'},
                //     {text:'Users', link:'/user/list'},
                //     {text:'Logs', link:'/logs'}
                //     ]},
            ]
        } 
    },
    methods:{
        navItemClicked(index){
            this.nav_items[index].is_open = !this.nav_items[index].is_open;
        },
        navSubItemClicked(index,sub_index){
            let link = this.nav_items[index]['items'][sub_index]['link'];
            if (link.length && link.length > 0)
            {
                this.$router.push(link);
            }
            
            
            
            
        },
        navTransEnter(element) {
                element.style.height = element.scrollHeight + 'px'
        },
        navTransAfterEnter(element) {
            element.style.height = ''
        },
        navTransBeforeLeave(element) {
            element.style.height = element.scrollHeight + 'px'
        },
        navTransAfterLeave(element) {
            element.style.height = ''
        },
    }

}
</script>

<style scoped lang="scss">
section{background: var(--color-main-color); width: 230px; height: 100vh;}
ul{ list-style:none; padding-left: 20px; padding-bottom: 10px;
    li{ margin-top: 10px; cursor: pointer; margin-bottom: 20px;
        ul{margin-left:15px}
        a.nav-title{color: white; transition: all 0.2s ease-out;}
        a.nav-sub-title{color: #f3efef; transition: all 0.2s ease-out;} 
        :hover{
            // a.nav-title{color: #090B53}
            // a.nav-sub-title{color: red; } 
            .icon-container{animation: animation-icon 0.2s ease-out;;}
        }
    }
}

.icon-container{display: flex; align-items: center; justify-content: center; width: 35px; height: 35px; background: rgb(243, 241, 241); border-radius: 5px; margin-right: 10px;
  img { 
      width:20px; height: 20px; margin-right: 10px; fill: red;
  }
}

@keyframes animation-icon {
  0%{ transform: rotate(5deg);}
  50%{ transform: rotate(-5deg);}
  100%{ transform: rotate(0deg);}
}
</style>