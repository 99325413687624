<template>
    <div :class="style +' button-main-style' " @click="onClick">
        <div>
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        title:{ type: String, default: ''},
        style:{ type: String, default: 'style1'},
        onClick:{'type':Function, required:true}
    }
}
</script>

<style scoped>
.button-main-style {display: flex; justify-content: center; align-items: center;cursor: pointer;}
.style1 {border-radius: 5px; background: #003399; width:100px; height: 40px;  color:white;  }
.style2 {border-radius: 5px; background: rgb(199, 198, 198); width:100px; font-size: 12px; height: 30px; color:black;  }

</style>