<template>
<section style='overflow:hidden'>
  <top-header ></top-header>
  <section class='flex' style='margin-top:75px;' >
 
      <navigation v-if="isLogin" class="nav-bar"></navigation>
      <div class='main-body-container'>
        <router-view class='width-100p'></router-view>
      </div> 
  </section>
</section>
</template>

<script>
import TopHeader from './components/layout/TopHeader.vue'
import Navigation from './components/layout/Navigation.vue'


export default {
  name: 'App',
  computed:{
    isLogin(){
      return (this.$route.path != '/login');
    }
  },
  components: {
    Navigation,
    TopHeader},
  created(){
    this.$store.dispatch('autoLogin');
  }
}
</script>


<style scoped>
.main-body-container{ height:calc(100vh - 75px); overflow:scroll; flex-grow: 1; padding-bottom:20px}
.nav-bar{height:calc(100vh - 75px); min-width:250px;}
</style>
<style lang="scss">
    $fa-font-path : "~@fortawesome/fontawesome-free-webfonts/webfonts";
    @import "~@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
    @import "~@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";
    @import "~@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
    @import "~@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";
</style>
<style >
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400,700&display=swap'); */

:root {
  --color-main-color: #090B53;
  --color-light-grey: #DCDBDD;
  --white: #ffffff;
}


*{padding: 0;margin: 0;box-sizing: border-box }
h1{font-size:30px; margin-bottom: 10px;}
body{margin:0; padding:0; background: #F8F8FB; font-family: 'Manrope',"Helvetica Neue", Helvetica, Arial, sans-serif;}
a { text-decoration: none; cursor: pointer;}
input {outline: none; }
hr {border: 0; height: 0.5px; background: #E4E5ED;}

.panel-view {border-radius: 8px; background:#e8eaed;  padding: 10px;}
.page-padding-sides {padding-left: 20px; padding-right: 20px; max-width:1200px  }
.page-margin-top {margin-top: 20px;}
.gap-20 {gap:20px}
.gap-13 {gap:30px}

.fs-18 {font-size: 18px !important;}
.items-center{align-items: center}
.flex{display: flex}
.flex-space-between {justify-content: space-between}
.flex-row-reverse {flex-direction: row-reverse}
.flex-11{flex: 1 1;}
.flex-11auto{flex: 1 1 auto;}
.text-center{text-align: center}

.mt-5 {margin-top: 5px !important}
.mt-10 {margin-top: 10px !important}
.mt-15 {margin-top: 15px !important}
.mt-20 {margin-top: 20px !important}
.mt-30 {margin-top: 30px !important}
.mt-40 {margin-top: 40px !important}
.mt-50 {margin-top: 50px !important}

.mb-5 {margin-bottom: 5px !important}
.mb-10 {margin-bottom: 10px !important}
.mb-20 {margin-bottom: 20px !important}
.mb-30 {margin-bottom: 30px !important}
.mb-40 {margin-bottom: 40px !important}
.mb-50 {margin-bottom: 50px !important}
 
.ml-5 {margin-left: 5px !important}
.ml-10 {margin-left: 10px !important}
.ml-20 {margin-left: 20px !important}

.mr-10 {margin-right: 10px !important}

.pt-10 {padding-top: 10px !important}
.pr-20 {padding-right: 20px !important}

.fw-500{font-weight: 500 !important;} 
.fs-25{font-size: 25px !important;}

.width-100p {width:100% !important}
.width-150 {width:150px !important}
.width-200 {width:200px !important}

.bgtest{background: blue !important}
.text-s1{font-style: normal; font-weight: 300; font-size: 16px; line-height: 19px; color: rgba(9, 11, 83, 0.5);}
.link-s1{font-style: normal; font-weight: 300; font-size: 16px; line-height: 19px; color: #FF95A5;}
.field-explain {font-size:12px; margin-top:10px; color:darkgrey}
.field-title{font-size: 14px}

input {-webkit-appearance: none; -moz-appearance: none; appearance: none; margin-top:5px;
    width:100%; background: white; padding: 0 10px; font-size:16px; border: 1px solid #E4E6F2;
               box-sizing: border-box; border-radius: 5px; Height: 45px; }

</style>
