<template>
    <section>
         <div>
            <slot></slot>
        </div> 
    </section>

</template>

<style scoped>

section{width:100%; padding: 20px; overflow: hidden; max-width:1240px} 
div {width:100%; }
</style>