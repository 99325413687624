import { createRouter, createWebHistory } from 'vue-router';
import Login from './pages/Login.vue';

import OrganisationList from './pages/organisations/OrganisationList.vue';
import OrganisationNew from './pages/organisations/OrganisationNew.vue';
import UserNew from './pages/users/UserNew.vue';
import UserList from './pages/users/UserList.vue';
import Logs from './pages/logs/Logs.vue';



import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(), 
    mode: 'history',
    routes:[
        {path:'/', component: OrganisationList, meta: { loginRequired: true }  },
        {path:'/home', component: OrganisationList, meta: { loginRequired: true }  }, 
        {path:'/organisations/list', component: OrganisationList, meta: { loginRequired: true }  }, 

        {path:'/login', component: Login},


        {path:'/organisations/list', component: OrganisationList, meta: { loginRequired: true }  }, 
        {path:'/organisation/new', component: OrganisationNew, meta: { loginRequired: true }  },  
        {path:'/organisation/edit/:org_id', component: OrganisationNew, meta: { loginRequired: true }  }, 

        {path:'/users/list', component: UserList, meta: { loginRequired: true }  }, 
        {path:'/user/new', component: UserNew, meta: { loginRequired: true }  },  
        {path:'/user/edit/:user_id', component: UserNew, meta: { loginRequired: true }  }, 

        {path:'/logs/api', component: Logs, meta: { loginRequired: true }  },  
        {path:'/logs/dev', component: Logs, meta: { loginRequired: true }  },  
        {path:'/logs/system', component: Logs, meta: { loginRequired: true }  },  
        {path:'/logs/error', component: Logs, meta: { loginRequired: true }  },  



        // {path:'/notification/new', component: NotificationEntity, meta: { loginRequired: true }  },  
        // {path:'/notification/list', component: NotificationList, meta: { loginRequired: true }  },  
        // {path:'/statistics/online-orders', component: StatisticsOrders, meta: { loginRequired: true }  },  
        // {path:'/statistics/online-order/:orderid', component: OrderDetails, meta: { loginRequired: true }  },  
        // {path:'/promocodes/generate', component: PromocodeGenerate, meta: { loginRequired: true }  }, 
        // {path:'/statistics/venues/monthly-growth', component: VenueMonthlyGrowth, meta: { loginRequired: true }  }, 
        // {path:'/user/list', component: PortalUsers, meta: { loginRequired: true }  }, 
    
        // {path:'/logs', component: Logs, meta: { loginRequired: true }  },
        // {path:'/login', component: Login},
    ]
});

router.beforeEach(function(to,_,next) {
  
  
  if (to.meta.loginRequired && !store.getters.isLoggedin) {
    // console.log(store.getters.isLoggedin); 
    next('/login');
  } else {
    // console.log('ok');
    next();
  }
});


export default router;
