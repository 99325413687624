<template>
<section >
  <page-header> {{ $route.path.split('/')[2].charAt(0).toUpperCase()+ $route.path.split('/')[2].slice(1)+' Logs' }}</page-header>
  <panel>
    <div class='flex' >
  
      <editbox v-if=" $route.path != '/logs/dev' && $route.path != '/logs/error' " style='max-width:300px' title="Log Date" @changed="LogDateChanged" type='date-picker'></editbox>
      <button-s1 class='width-200 ml-10 mt-30' :onClick="getLogData">Get Logs</button-s1>
      <button-s1 class='width-200 ml-10 mt-30' :onClick="cleaLogs">Clear Logs</button-s1>
    </div>
  </panel>
  
  <panel>
    <div class='log-text'>
     <pre>{{log_str}}</pre>
    </div>
  </panel>

</section>
</template>

<script>
import { onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'

export default {
  setup(){
    const route = useRoute()
    const store = useStore();
    const log_str = ref('Log file is empty')

    let page_kind = route.path.split('/')[2];
    
    let now_date = new Date();
    let log_date = now_date.toISOString().replace('T',' ').substr(0,19);

    
    watch(route, () => {
      pageSetup()
    });

    const pageSetup = () => {
      page_kind = route.path.split('/')[2];
      console.log(page_kind);
      getLogData();
    }

    const LogDateChanged = (value) => {
      log_date = value.toISOString().split('T')[0];
    }

    const cleaLogs = async ()=>{
  
          let result = await store.dispatch('requests/sendRequest', {
            endpoint:'logs',
            params:{
                type:page_kind,
                log_date:log_date.split(' ')[0]
            },
            method:'delete'           
          });
          // console.log('result', result);
          
          log_str.value = result;

    }

    const getLogData = async ()=>{
    
      
         let params = {
                type:page_kind,
                log_date:log_date.split(' ')[0]
          }

          let result = await store.dispatch('requests/sendRequest', {
            endpoint:'logs',
            params:params,
            method:'get'           
          });
          // console.log('result', result);
          
          log_str.value = result;

          // if (result.result == 'success'){
          //   this.$router.push('/users/list');
          // }
          // else this.error_value = 'Error registering user.';
      
      
    }

    onMounted(pageSetup)
    return {log_str,LogDateChanged,getLogData,cleaLogs}
  }
  
}
</script>

<style scoped>
.log-text {font-size: 12px; overflow: scroll}
</style>