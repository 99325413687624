<template>
    <section class='comp-container'>
        <div class='title'> {{title}} </div>
        <div class='mt-20 flex items-center'>
            <img class='comp-img' :src="require('@/assets/'+icon)">
            
            <div class='comp-value'>{{show_value}}</div>

        </div>
    </section>
</template>

<script>
export default {
    props: {
        title:{ type: String, default: ''},
        value:{ type: Number, default: 0},
        icon:{ type: String, default: 'icn_report.svg'},
        // onClick:{'type':Function, required:true}
    },
    data(){
        return{
          show_value:0
        }
    },
    watch: {
        value(new_value){
            this.animateValue(new_value);
        }
    },
    methods:{
        animateValue(new_value){
                
                let start = this.show_value;
                let end = new_value;
                var increment = Math.round((end - start) / 50);
               
               let timer = setInterval( () => {
                    this.show_value += increment;
                    
                    if (this.show_value >= new_value) {
                        clearInterval(timer);
                        this.show_value = new_value;
                    }
                }, 1);


        
        }
    }
  
}
</script>

<style scoped lang="scss">
.comp-container {border-radius: 8px; background:white; flex: 1 1; height: 90px; padding: 10px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);}
.comp-value {font-weight: 700; font-size:22px; margin-left:10px;}
.comp-img {width:30px; height:30px}
/* .container {border-radius: 5px; background: #FD464B; width:100px; height: 30px; display: flex; justify-content: center; align-items: center; color:white; cursor: pointer; } */

</style>


