<template>
<section style=''>
  
  <page-header>Portal Users</page-header>


  <table-s1 class='mt-20' title="Order list" :header_titles=table_header_titles :lines=table_lines></table-s1>


</section>
</template>

<script>


export default {
  components: {
  },
  data(){
    return{
      table_header_titles : ['ID',	'Name',	'Email',''],
      table_lines:[]
    }
  },
   
  methods:{
    async getPageData(){
      
        let params = {
        }
        
        
        let result = await this.$store.dispatch('requests/sendRequest', {
          endpoint:'users',  
          method:'get',       
          params:params             
        });
        console.log(result);
        
        this.analyseTableData(result ?? [])
        
    },
    analyseTableData(result_data){
      
      this.table_lines = [];

      result_data.forEach(line => {
        this.table_lines.push([
          line.table_id,
          line.name, 
          line.email,
          [{type:'icon-button', link:'/user/edit/'+line.table_id, icon:'edit'}]
        ]);
      });

    }
    
  },
  mounted(){
    this.getPageData();
  }
}
</script>

<style scoped>

</style>